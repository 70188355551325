import I18n from "i18n-js"
import React, { useMemo } from "react"

interface Option {
  id: number
  name: string
  meta: { image: string }
}

interface Slot {
  name: string
  options: Option[]
}

interface TopRank {
  nickname: string
  profile_id: number
  rank: number
  points: number
  country_code: string
  slots?: Slot[]
}

const displayPoints = (points: number) => {
  if (points === 0) {
    return "E"
  }
  return points.toLocaleString(undefined, { signDisplay: "always" })
}

const EmptySlot = () => {
  return <div className="top3Ranks__item__picksEmptySlot" />
}

const Slot = ({options}: Slot) => {
  const option = options[0]
  if (option) {
    return (
        <div className="top3Ranks__item__picksSlot">
          {option?.meta?.image && <img src={option.meta.image}/>}
        </div>
    )
  } else {
    return <EmptySlot />
  }
}


const Rank = ({ nickname, rank, points, country_code: countryCode, slots: rankSlots }: TopRank) => {
  const { slots, team, teamName } = useMemo(() => {

    const tierSlots = rankSlots?.filter((slot) => slot.name.startsWith("tier"))

    const slots = Array.from({ length: 4 }, (_, index) => tierSlots?.[index] ?? null)

    const team = rankSlots?.find((slots) => slots.name === "teams")
    const option =  team?.options[0]
    const teamName = option?.name.toLocaleLowerCase().replace(" ", "")

    return {
      slots,
      team,
      teamName,
    }
  }, [rankSlots])

  return <>
    <div className="top3Ranks__item__topContainer">
      <div className="top3Ranks__item__rank">{rank}</div>
      <div className="top3Ranks__item__info">
        <div className="top3Ranks__item__infoName">{nickname}</div>
        <div className="top3Ranks__item__infoCountry">
          {(countryCode && countryCode !== "undefined") && (
            <>
              <div className={`roundFlag--${countryCode}`} />
              <div>{countryCode}</div>
            </>
          )}
        </div>
      </div>
    </div>
    <div className="top3Ranks__item__divider" />
    <div className="top3Ranks__item__bottomContainer">
      <div className="top3Ranks__item__picksContainer">
        <div className="top3Ranks__item__picksTitle">{I18n.t("ranking.top_3.team")}</div>
        <div className="top3Ranks__item__picks">
          {slots.map((slot) => {
            if (slot) {
              return <Slot {...slot} />
            }
            return <EmptySlot />
          })}
        </div>
      </div>
      <div className="top3Ranks__item__teamAndScore">
        <div className="top3Ranks__item__teamPick">
          {team && team.options[0] ? (
            <div className="top3Ranks__item__teamPickInfo">
              <div className={`top3Ranks__item__teamPickSlot livTeam__col--${teamName} livTeam__img--${teamName}`} />
              <div className="top3Ranks__item__teamPickName">{team.options[0].name}</div>
            </div>
          ) : (
            <div className="top3Ranks__item__teamPickInfo">
              <div className="top3Ranks__item__emptyTeamPickSlot" />
              <div className="top3Ranks__item__teamPickName">-</div>
            </div>
          )}
        </div>
        <div className="top3Ranks__item__scoreContainer">
          <div className="top3Ranks__item__scoreTitle">{I18n.t("ranking.top_3.score")}</div>
          <div className="top3Ranks__item__score">{displayPoints(points)}</div>
        </div>
      </div>
    </div>
  </>
}

interface Props {
  top_3: TopRank[]
  urls: string[]
}

const Top3Ranks = ({ top_3: top3, urls }: Props) => {
  return <div className="top3Ranks__container">
    {top3.map((rank, index) => {
      return <a className="top3Ranks__item" href={urls[index]}>
        <Rank {...rank} />
      </a>
    })}
  </div>
}

export default Top3Ranks