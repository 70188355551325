import React, { useCallback, useState } from "react"
import Modal from "react-modal"
import I18n from "i18n-js"

import Form from "./CommunitySettingsForm"
import Button from "./Button"

interface Props {
  community_action: string
  authenticity_token: string
  community_name: string
  community_description: string
  community_private: boolean
  method: string
  flash?: {
    community_error?: string
  }
  button_primary?: boolean
}

Modal.setAppElement("#neoApp")

const CreateCommunityModal = ({
  community_action: communityAction,
  authenticity_token: authenticityToken,
  community_name: communityName,
  community_description: communityDescription,
  community_private: communityPrivate,
  button_primary: buttonPrimary,
  method,
  flash,
}: Props) => {
  const [isOpen, setOpen] = useState(!!flash?.community_error)

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Button title={I18n.t("community.create.btn_new_community")} onClick={openModal} primary={buttonPrimary} />
      {/* Issue seems to stem from ts support of the package
      @ts-ignore-next-line */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        overlayClassName="neo__modalOverlay"
        className="neo__modalContent"
        parentSelector={() => document.getElementById("neoApp")!}
      >
        <div className='changeCommunitySettings'>
          <div className='changeCommunitySettings__header'>
            <h2 className='changeCommunitySettings__title'>{I18n.t('community.create.title')}</h2>
            <div className='changeCommunitySettings__icon icon pointer' onClick={closeModal} />
          </div>
          <Form
            community_action={communityAction}
            authenticity_token={authenticityToken}
            community_name={communityName}
            community_description={communityDescription}
            community_private={communityPrivate}
            method={method}
            flash={flash}
          />
        </div>
      </Modal>
    </>
  )
}

export default CreateCommunityModal
