import React, { useCallback, useRef, useState } from "react"
import I18n from "i18n-js"

import Button from "./Button"

interface SliderButtonProps {
  id: string
  defaultChecked: boolean
  label?: string
}

const SliderButton = ({ id, defaultChecked, label }: SliderButtonProps) => {
  const [checked, setChecked] = useState(defaultChecked)

  const sliderClasses = [
    "changeCommunitySettings__sliderButton__slider",
    checked
      ? "changeCommunitySettings__sliderButton__slider--checked"
      : "changeCommunitySettings__sliderButton__slider--unchecked",
  ].join(" ")

  const toggleSliderButton = useCallback(() => {
    setChecked((value) => !value)
  }, [])
  
  return <div className="changeCommunitySettings__sliderButton" onClick={toggleSliderButton}>
    <div className={sliderClasses}>
      <input hidden id={id} type="checkbox" value={checked.toString()} checked={checked} name="community[private]" />
      <div className="changeCommunitySettings__sliderButton__bubble" />
    </div>
    <div className="changeCommunitySettings__sliderButton__label">
      <div className="changeCommunitySettings__sliderButton__icon" />
      <label htmlFor={id}>{label}</label>
    </div>
  </div>
}

type FormRowProps = React.PropsWithChildren<{
  label?: string
  second?: boolean
  subLabel?: string
  center?: boolean
}>

const FormRow = ({ label, children, second, subLabel, center }: FormRowProps) => {
  const secondClass = (second) ? " changeCommunitySettings__row--second" : ""
  const centerClass = (center) ? " changeCommunitySettings__row--center" : ""
  return (
    <div className={"changeCommunitySettings__row" + secondClass + centerClass}>
      <div className="changeCommunitySettings__labelContainer">
        <div className="changeCommunitySettings__label">{label}</div>
        {subLabel && <div className="changeCommunitySettings__subLabel">{subLabel}</div>}
      </div>
      <div className="changeCommunitySettings__input">
        {children}
      </div>
    </div>
  )
}

interface PrivacyRadioProps {
  id: string
  label: string
  subLabel: string
  iconClass: string
  value: string | number
  checked: boolean
}

const PrivacyRadio = ({ id, label, subLabel, iconClass, value, checked }: PrivacyRadioProps) => {
  return (
    <div className="radio changeCommunitySettingsTable__radio pointerForAllChildren">
      <input id={id} className="radio__input" type="radio" value={value} defaultChecked={checked} name="community[private]"/>
      <label className="radio__label" htmlFor={id}>
        <div className={`radio__title radio__title--icon icon ${iconClass}`}>
          {label}
        </div>
        <div className="radio__title radio__title--sub">
          {subLabel}
        </div>
      </label>
    </div>
  )
}

interface Props {
  community_action: string
  authenticity_token: string
  community_name: string
  community_description: string
  community_private: boolean
  method: string
  flash?: {
    community_error?: string
  }
}

const Form = ({
  community_action: communityAction,
  authenticity_token: authenticityToken,
  community_name: communityName,
  community_description: communityDescription,
  community_private: communityPrivate,
  method,
  flash,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null)
  const nameClass = "input" + ((flash?.community_error) ? " input--error" : "")

  return (
    <div>
      <form
        ref={formRef}
          className="changeCommunitySettings__main"
          action={communityAction}
          acceptCharset="utf-8"
          method="post"
        >
        <input type="hidden" name="_method" value={method} autoComplete="off" />
        <input type="hidden" name="authenticity_token" value={authenticityToken} />
        <FormRow label={I18n.t("community_settings.label.communtiy_name")}>
          <input
            className={nameClass}
            type="text"
            defaultValue={communityName}
            name="community[title]"
            id="community_title"
            maxLength={32}
          />
          {flash?.community_error && (
            <span className="form__error">
              {flash?.community_error}
            </span>
          )}
        </FormRow>
        <FormRow
          label={I18n.t("community_settings.label.community_description")}
          subLabel={I18n.t("community_settings.label.explanation")}
        >
          <div className="changeCommunitySettings__input__textareaContainer">
            <textarea
              className="input"
              rows={10}
              name="community[description]"
              id="community_description"
              defaultValue={communityDescription}
            />
          </div>
        </FormRow>
        <FormRow label={I18n.t("community_settings.label.privacy")}>
          <SliderButton
            id="community_privacy"
            defaultChecked={communityPrivate}
            label={I18n.t("community_settings.private_league")}
          />
        </FormRow>
        <FormRow center>
          <Button
            primary
            className="changeCommunitySettings__button"
            onClick={() => formRef.current?.submit()}
          >
            {I18n.t("community_settings.save")}
          </Button>
        </FormRow>
      </form>
    </div>
  )
}

export default Form