import exportGlobally from '../misc/exportGlobally'
import Rails from '@rails/ujs'

// [Screen, ScreenSet]
const screens = {
    'register': ['gigya-register-screen', 'livgolf-RegistrationLogin'],
    'login': ['gigya-login-screen', 'livgolf-RegistrationLogin'],
    'edit_profile': ['gigya-update-profile-screen', 'livgolf-ProfileUpdate']
}

// Use "fantasy" as origin

function loginRedirect() {
    if (window.gigya) {
        gigya.sso.login({
            authFlow: 'redirect',
            context: {
                myCustomSessionExpiration: 60 * 60 * 24 * 3,
                origin: 'fantasy'
            },
            redirectURL: window.location.origin
        })
    }
}

const authenticationEndpoint = '/accounts/sign_in'
const logoutEndpoint = '/accounts/sign_out'

const showModal = function (action) {
    if (window.gigya) {
        var [startScreen, screenSet] = screens[action]
        gigya.accounts.showScreenSet({screenSet: screenSet, startScreen: startScreen})
    }
}

let gigyaReady = false
const hooks = {
    'login': () => loginRedirect(),
    'register': () => showModal('register'),
    'edit_profile': () => showModal('edit_profile'),
    'isGigyaReady': () => gigyaReady,
}

function postForm(path, params) {
    var form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', path)

    const csrfParam = Rails.csrfParam()
    if (csrfParam !== null) {
        params[csrfParam] = Rails.csrfToken()
    }

    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('name', key)
            hiddenField.setAttribute('value', params[key])

            form.appendChild(hiddenField)
        }
    }

    document.body.appendChild(form)
    form.submit()
}

function getJWTAndPostLogin(account_data, profile_data) {
    // account:
    // {"fantasy":{"teamName":"Fantasy"},"dateOfBirth":"1990-01-01","favoriteTeam":{"since":"2024-07-09T09:21:39"},"dataSource":"Testers"}
    // profile:
    // {"firstName":"Test","lastName":"Testerer","age":34,"birthDay":1,"birthMonth":1,"birthYear":1990,"country":"United States","email":"ma-test100@neopoly.de"}
    if (account_data.fantasy && account_data.fantasy.teamName) {
        gigya.accounts.getJWT({
            'callback': function (jwt_response) {
                if (jwt_response.errorCode === 0) {
                    postForm(authenticationEndpoint, {
                        'token': jwt_response.id_token,
                        'nickname': account_data.fantasy.teamName,
                        'firstname': profile_data.firstName,
                        'lastname': profile_data.lastName,
                        'email': profile_data.email,
                        'country': profile_data.country
                    })
                } else {

                }
            }
        })
    }
}

function gigyaAccountCallback(response) {
    if (response.errorCode === 0 && response.UID) {
        getJWTAndPostLogin(response.data, response.profile)
    }
}

var onGigyaLogin = function (_response) {
    if (!window.fantasyUserLoggedIn) {
        gigya.accounts.getAccountInfo({
            callback: gigyaAccountCallback
        })
    }
}

var onGigyaLogout = function (_response) {
    if (window.fantasyUserLoggedIn) {
        postForm(logoutEndpoint, {})
    }
}

function addEventHooks(sdk) {
    sdk.accounts.addEventHandlers({
        onLogin: onGigyaLogin,
        onLogout: onGigyaLogout,
    })
}

function checkLogin(response) {
    gigyaReady = true;
    if (response.UID) {
        if (!window.fantasyUserLoggedIn) {
            getJWTAndPostLogin(response.data, response.profile)
        }
    } else {
        if (window.fantasyUserLoggedIn) {
            postForm(logoutEndpoint, {})
        } else {
            if(document.getElementById("register_card")){
                var [startScreen, screenSet] = screens["register"]
                gigya.accounts.showScreenSet({
                    containerID: "register_card",
                    screenSet: screenSet,
                    startScreen: startScreen,
                })
            }
        }
    }
}


function setup() {
    // we might have some other code relying on the global callback
    // so we need to alias it, and call it when needed.
    const existingCallback = window.onGigyaServiceReady
    window.onGigyaServiceReady = function () {
        if (existingCallback) {
            existingCallback()
        }

        addEventHooks(window.gigya)

        window.gigya.accounts.getAccountInfo({callback: checkLogin});
    }
}

let __initialized = false

const initialize = () => {
    if (__initialized) {
        return
    }
    __initialized = true
    exportGlobally('gigya', hooks)

    exportGlobally('gigyaDebug', {
        login: () => {
            postForm(authenticationEndpoint, {
                'token': "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Eci61G6w4zh_u9oOCk_v1M_sKcgk0svOmW4ZsL-rt4ojGUH2QY110bQTYNwbEVlowW7phCg7vluX_MCKVwJkxJT6tMk2Ij3Plad96Jf2G2mMsKbxkC-prvjvQkBFYWrYnKWClPBRCyIcG0dVfBvqZ8Mro3t5bX59IKwQ3WZ7AtGBYz5BSiBlrKkp6J1UmP_bFV3eEzIHEFgzRa3pbr4ol4TK6SnAoF88rLr2NhEz9vpdHglUMlOBQiqcZwqrI-Z4XDyDzvnrpujIToiepq9bCimPgVkP54VoZzy-mMSGbthYpLqsL_4MQXaI1Uf_wKFAUuAtzVn4-ebgsKOpvKNzVA",
                'nickname': "Debuggers",
                'firstname': "John",
                'lastname': "Doe",
                'email': "john.doe@example.com",
                'country': "United States"
            })
        }
    })

    setup()
}

export {hooks, initialize}
