import React, { useEffect, useState } from "react"

import _ from "lodash"
import axios, { AxiosResponse } from "axios"
import I18n from "i18n-js"

interface DayScoring {
  name: string
  score: number
  is_future: boolean
}

interface ScoringSummary {
  days: DayScoring[]
  is_running: boolean
  total: number
}

type Error = { error: string }

interface ProfileInfo {
  nickname: string
  firstname: string
  lastname: string
}

interface Props {
  round_id: number
  summary: ScoringSummary
  authenticity_token: string
  profile_info: ProfileInfo
}

const everyTwoMinutes = 2 * 60 * 1000

const isErrorResponse = (res: any | AxiosResponse<Error>): res is AxiosResponse<Error> => {
  return !!(res as AxiosResponse<Error>)?.data?.error
}

const scoreValue = (score: number) => {
  return score === 0 ? "E" : score.toLocaleString(I18n.currentLocale(), { signDisplay: "always" })
}

const ScoringSummaryComponent: React.FC<Props> = ({
  round_id: roundId,
  summary,
  authenticity_token,
  profile_info: profileInfo,
}) => {

  const [{days, total, is_running: isRunning}, setSummary] = useState(summary)

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(async () => {
        try {
          const res = await axios.post<ScoringSummary>(`/round/${roundId}/scoring_summary`, { authenticity_token })
          res.data.is_running = res.data.is_running || res.data.is_running === undefined
          setSummary(res.data)
        } catch (e: any) {
          const res = e.response as AxiosResponse<Error> | undefined
          if (isErrorResponse(res)) {
            console.error(res.status, res.data.error)
          } else {
            console.error(e)
          }
        }
      }, everyTwoMinutes)
      return () => {
        clearInterval(interval);
      }
    }
  }, [isRunning])

  return <div className="scoringSummary">
    {profileInfo.nickname && (profileInfo.nickname !== "" || profileInfo.firstname !== "" || profileInfo.lastname !== "") &&
      <div className="scoringSummary__nickname__section">
        <div className="scoringSummary__nickname__circle">{profileInfo.firstname[0] || ""}{profileInfo.lastname[0] || ""}</div>
        <span className="scoringSummary__nickname"><span>{profileInfo.nickname}</span></span>
      </div>}
    <div className="scoringSummary__scores__section">
      <h4>{I18n.t("my_predictions.scoring_summary.title")}</h4>
      <div className="scoringSummary__scores">
        {_.map(days, ({name, score}) => (
          <div key={name} className="scoringSummary__block">
            <div className="scoringSummary__eventName">{name}</div>
            <div className={`scoringSummary__score ${score === 0 ? "scoringSummary__score--none" : ""}`}>{scoreValue(score)}</div>
          </div>
        ))}
        <div className="scoringSummary__block">
          <div className="scoringSummary__eventName">{I18n.t("my_predictions.scoring_summary.label_total")}</div>
          <div className={`scoringSummary__score ${total === 0 ? "scoringSummary__score--none" : ""}`}>{scoreValue(total)}</div>
        </div>
      </div>
    </div>
  </div>
}

export default ScoringSummaryComponent